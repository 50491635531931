import React from 'react'
import MediaQuery from 'react-responsive'
import Layout from '../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
	ContactForm,
} from '../components/layouts'

import {
	Title,
	Paragraph,
	Footer
} from '../components/ui'

export default class ErrorPage extends React.Component {
	render(){
		return (
			<Layout>
				<Section paddingTop={'8em'} paddingBottom={'20em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'3em'} marginLeft={'0'}>NOT FOUND</Title>
								<Paragraph fontSize={'1em'}>You just hit a route that doesn&#39;t exist... the sadness.</Paragraph>
							</Column>
						</Row>
					</Container>
				</Section>
			</Layout>
		)
	}
}
